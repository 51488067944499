import React, { useEffect, useState } from 'react'
import { isAndroid, isIOS, isIOS13, isMobile } from 'react-device-detect'

const DeeplinkPortal: React.FC = () => {
  const deeplink = import.meta.env.NX_DEEPLINK_PATIENT_APP
  const landingpage = 'https://telesmartcare.com'
  const appstore = 'https://apple.co/6503246160'
  const playstore =
    'https://play.google.com/store/apps/details?id=com.samitivejchonburi.telesmartcare'
  const [fallbackUrl, setFallbackUrl] = useState<string>(landingpage)
  const [showBtnOpenApp, setShowBtnOpenApp] = useState(false)
  useEffect(() => {
    const delay = setTimeout(() => {
      setShowBtnOpenApp(true)
    }, 500)

    return () => clearTimeout(delay)
  }, [])
  useEffect(() => {
    let url = ''
    if (isMobile) {
      url = deeplink + window.location.pathname + window.location.search
      let _fallbackUrl = landingpage
      if (isIOS || isIOS13) {
        _fallbackUrl = appstore
      } else if (isAndroid) {
        _fallbackUrl = playstore
      }
      setFallbackUrl(_fallbackUrl)
      window.location.href = url
    } else {
      window.location.href = landingpage
    }
  }, [deeplink])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '99vh',
      }}
    >
      <img src="../favicon.ico" alt="Logo" width={200} />
      {showBtnOpenApp && (
        <button
          className="btn-open-app"
          onClick={() => {
            window.location.href = fallbackUrl
          }}
        >
          Open
        </button>
      )}
    </div>
  )
}

export default DeeplinkPortal
